const info1 = `
  ---

  ##### See your top 5 Spotify Tracks & Artists across time`;

const info2 = `
  **Don't have a Spotify account?**`;

const demoButton = `
  **Demo Page**
  
  (Under Construction)
  `;

// const mockedData = {
//   trackData: {
//     short_term: 'https://mocki.io/v1/abbf7524-63bd-4fef-a8c0-9439c98d41e9',
//     medium_term: 'https://mocki.io/v1/abbf7524-63bd-4fef-a8c0-9439c98d41e9',
//     long_term: 'https://mocki.io/v1/abbf7524-63bd-4fef-a8c0-9439c98d41e9',
//   },
// }
const mockedData = {
  trackData: [
    {
      time_range: "short_term",
      track_data: [
        {
          artist: "Gunship",
          title: "Tech Noir 2 (feat. John Carpenter, Charlie Simpson)",
          trackLink: "https://open.spotify.com/track/6sm8udxa0SvDUMJ2jchWe7",
          uri: "spotify:track:6sm8udxa0SvDUMJ2jchWe7",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e02c702f094fa5afa44b17a8fa4",
        },
        {
          artist: "Best Frenz",
          title: "The Adirondacks",
          trackLink: "https://open.spotify.com/track/2ReGrjKu7SfLVKtRm18GCs",
          uri: "spotify:track:2ReGrjKu7SfLVKtRm18GCs",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e021b13fb8312a0262bf9c018ec",
        },
        {
          artist: "Best Frenz",
          title: "Welcome To The Mall",
          trackLink: "https://open.spotify.com/track/1F9g5vvh5cchv6paEzzJmO",
          uri: "spotify:track:1F9g5vvh5cchv6paEzzJmO",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e021b13fb8312a0262bf9c018ec",
        },
        {
          artist: "Sufjan Stevens",
          title: "Goodbye Evergreen",
          trackLink: "https://open.spotify.com/track/2GEs9D56FRHqUAEwvlvBpw",
          uri: "spotify:track:2GEs9D56FRHqUAEwvlvBpw",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e0232a8498ea892ccd4fcf3f6cf",
        },
        {
          artist: "Gunship",
          title: "Monster in Paradise (feat. Milkie Way, Dave Lombardo, Tyler Bates)",
          trackLink: "https://open.spotify.com/track/5WMn5op2E9aYUm5rk2ZNpY",
          uri: "spotify:track:5WMn5op2E9aYUm5rk2ZNpY",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e02c702f094fa5afa44b17a8fa4",
        },
      ],
    },
    {
      time_range: "medium_term",
      track_data: [
        {
          artist: "Best Frenz",
          title: "Throw Your Coins, Make A Wish",
          trackLink: "https://open.spotify.com/track/76TXfqsg6GaJ8o179sRvvq",
          uri: "spotify:track:76TXfqsg6GaJ8o179sRvvq",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e02a3da2e3b54601d007defc4cd",
        },
        {
          artist: "Best Frenz",
          title: "Welcome To The Mall",
          trackLink: "https://open.spotify.com/track/1F9g5vvh5cchv6paEzzJmO",
          uri: "spotify:track:1F9g5vvh5cchv6paEzzJmO",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e021b13fb8312a0262bf9c018ec",
        },
        {
          artist: "Gunship",
          title: "Tech Noir 2 (feat. John Carpenter, Charlie Simpson)",
          trackLink: "https://open.spotify.com/track/6sm8udxa0SvDUMJ2jchWe7",
          uri: "spotify:track:6sm8udxa0SvDUMJ2jchWe7",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e02c702f094fa5afa44b17a8fa4",
        },
        {
          artist: "Martinů Voices",
          title: "Byzantium (The Ancient Era)",
          trackLink: "https://open.spotify.com/track/0gSy84Fl22VMIXCQ54GSYv",
          uri: "spotify:track:0gSy84Fl22VMIXCQ54GSYv",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e02f260e780e1dfbf5187f5c640",
        },
        {
          artist: "Best Frenz",
          title: "The Adirondacks",
          trackLink: "https://open.spotify.com/track/2ReGrjKu7SfLVKtRm18GCs",
          uri: "spotify:track:2ReGrjKu7SfLVKtRm18GCs",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e021b13fb8312a0262bf9c018ec",
        },
      ],
    },
    {
      time_range: "long_term",
      track_data: [
        {
          artist: "Best Frenz",
          title: "Ugly Ending",
          trackLink: "https://open.spotify.com/track/5nd388N2RM419Jq23GRACT",
          uri: "spotify:track:5nd388N2RM419Jq23GRACT",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e0268d2fd4acf0b6d1808b0ea03",
        },
        {
          artist: "Everything Everything",
          title: "The Actor",
          trackLink: "https://open.spotify.com/track/1KUXZIqGRp14Py0WEso28g",
          uri: "spotify:track:1KUXZIqGRp14Py0WEso28g",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e0254ae10c175eaf83b6596bf5c",
        },
        {
          artist: "of Montreal",
          title: "We Will Commit Wolf Murder",
          trackLink: "https://open.spotify.com/track/1t5eRyRqqt6F8P5xeZcS2z",
          uri: "spotify:track:1t5eRyRqqt6F8P5xeZcS2z",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e02a5b21e864087c7ab7f448f91",
        },
        {
          artist: "Best Frenz",
          title: "30% Off!",
          trackLink: "https://open.spotify.com/track/7mRbbFn1Ip7hp1NoJQjOtB",
          uri: "spotify:track:7mRbbFn1Ip7hp1NoJQjOtB",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e0268d2fd4acf0b6d1808b0ea03",
        },
        {
          artist: "Japanese Breakfast",
          title: "Paprika",
          trackLink: "https://open.spotify.com/track/3zyqphgXvgHe436IMKeey3",
          uri: "spotify:track:3zyqphgXvgHe436IMKeey3",
          albumUrl: "https://i.scdn.co/image/ab67616d00001e02b103a69ed742a7735a2ba7e8",
        },
      ],
    },
  ],
  artistData: [
    {
      time_range: "short_term",
      artist_data: [
        {
          artistName: "Gunship",
          uri: "spotify:artist:3PALZKWkpwjRvBsRmhlVSS",
          artistLink: "https://open.spotify.com/artist/3PALZKWkpwjRvBsRmhlVSS",
          imageUrl: "https://i.scdn.co/image/ab6761610000517496a96f31fdff768dd1245905",
          genre: "Popwave",
        },
        {
          artistName: "Sufjan Stevens",
          uri: "spotify:artist:4MXUO7sVCaFgFjoTI5ox5c",
          artistLink: "https://open.spotify.com/artist/4MXUO7sVCaFgFjoTI5ox5c",
          imageUrl: "https://i.scdn.co/image/ab67616100005174b80dd6b23c5c04d62d9aa0c6",
          genre: "Art Pop",
        },
        {
          artistName: "Best Frenz",
          uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
          artistLink: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
          imageUrl: "https://i.scdn.co/image/ab676161000051748d2104d4cb504d383b9e9ce6",
          genre: "[No Genre]",
        },
        {
          artistName: "Hippo Campus",
          uri: "spotify:artist:1btWGBz4Uu1HozTwb2Lm8A",
          artistLink: "https://open.spotify.com/artist/1btWGBz4Uu1HozTwb2Lm8A",
          imageUrl: "https://i.scdn.co/image/ab67616100005174a540d61efaef0e682025c509",
          genre: "Minneapolis Indie",
        },
        {
          artistName: "Miniature Tigers",
          uri: "spotify:artist:7xWU2A2lw1xf4zTjKhkrGK",
          artistLink: "https://open.spotify.com/artist/7xWU2A2lw1xf4zTjKhkrGK",
          imageUrl: "https://i.scdn.co/image/ab6761610000517455229ad1e9f45be6e601ef1c",
          genre: "Stomp Pop",
        },
      ],
    },
    {
      time_range: "medium_term",
      artist_data: [
        {
          artistName: "Geoff Knorr",
          uri: "spotify:artist:1OFCjpSAsyIOWWzIAYb3OI",
          artistLink: "https://open.spotify.com/artist/1OFCjpSAsyIOWWzIAYb3OI",
          imageUrl: "https://i.scdn.co/image/ab6761610000517481dfa97becd8f3c8e95a5e5d",
          genre: "Video Game Music",
        },
        {
          artistName: "Best Frenz",
          uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
          artistLink: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
          imageUrl: "https://i.scdn.co/image/ab676161000051748d2104d4cb504d383b9e9ce6",
          genre: "[No Genre]",
        },
        {
          artistName: "Gunship",
          uri: "spotify:artist:3PALZKWkpwjRvBsRmhlVSS",
          artistLink: "https://open.spotify.com/artist/3PALZKWkpwjRvBsRmhlVSS",
          imageUrl: "https://i.scdn.co/image/ab6761610000517496a96f31fdff768dd1245905",
          genre: "Popwave",
        },
        {
          artistName: "Dirt Poor Robins",
          uri: "spotify:artist:16lGevDffmuRakKVvdJ983",
          artistLink: "https://open.spotify.com/artist/16lGevDffmuRakKVvdJ983",
          imageUrl: "https://i.scdn.co/image/ab67616100005174a99e6039483b51e0d719e2fc",
          genre: "Dark Cabaret",
        },
        {
          artistName: "Hippo Campus",
          uri: "spotify:artist:1btWGBz4Uu1HozTwb2Lm8A",
          artistLink: "https://open.spotify.com/artist/1btWGBz4Uu1HozTwb2Lm8A",
          imageUrl: "https://i.scdn.co/image/ab67616100005174a540d61efaef0e682025c509",
          genre: "Minneapolis Indie",
        },
      ],
    },
    {
      time_range: "long_term",
      artist_data: [
        {
          artistName: "Everything Everything",
          uri: "spotify:artist:1HOeqtP7tHkKNJNLzQ2tnr",
          artistLink: "https://open.spotify.com/artist/1HOeqtP7tHkKNJNLzQ2tnr",
          imageUrl: "https://i.scdn.co/image/ab676161000051744ae4999521cdc4a1f35c437e",
          genre: "British Indie Rock",
        },
        {
          artistName: "of Montreal",
          uri: "spotify:artist:5xeBMeW0YzWIXSVzAxhM8O",
          artistLink: "https://open.spotify.com/artist/5xeBMeW0YzWIXSVzAxhM8O",
          imageUrl: "https://i.scdn.co/image/ab67616100005174f3e93c0583fdb0c1b083ccf8",
          genre: "Art Pop",
        },
        {
          artistName: "Dirt Poor Robins",
          uri: "spotify:artist:16lGevDffmuRakKVvdJ983",
          artistLink: "https://open.spotify.com/artist/16lGevDffmuRakKVvdJ983",
          imageUrl: "https://i.scdn.co/image/ab67616100005174a99e6039483b51e0d719e2fc",
          genre: "Dark Cabaret",
        },
        {
          artistName: "Geoff Knorr",
          uri: "spotify:artist:1OFCjpSAsyIOWWzIAYb3OI",
          artistLink: "https://open.spotify.com/artist/1OFCjpSAsyIOWWzIAYb3OI",
          imageUrl: "https://i.scdn.co/image/ab6761610000517481dfa97becd8f3c8e95a5e5d",
          genre: "Video Game Music",
        },
        {
          artistName: "Kevin Penkin",
          uri: "spotify:artist:4S33gEeiCjwfD1FJKfMT3Y",
          artistLink: "https://open.spotify.com/artist/4S33gEeiCjwfD1FJKfMT3Y",
          imageUrl: "https://i.scdn.co/image/ab676161000051744d7889d9cf5a026c646cb200",
          genre: "Anime Score",
        },
      ],
    },
  ],
};
const mockedData2 = {
  trackData: {
    short_term: {
      items: [
        {
          album: {
            album_type: "ALBUM",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/3PALZKWkpwjRvBsRmhlVSS",
                },
                href: "https://api.spotify.com/v1/artists/3PALZKWkpwjRvBsRmhlVSS",
                id: "3PALZKWkpwjRvBsRmhlVSS",
                name: "Gunship",
                type: "artist",
                uri: "spotify:artist:3PALZKWkpwjRvBsRmhlVSS",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/4uAOIj4Sxh4sL6G0v5kykv",
            },
            href: "https://api.spotify.com/v1/albums/4uAOIj4Sxh4sL6G0v5kykv",
            id: "4uAOIj4Sxh4sL6G0v5kykv",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b273c702f094fa5afa44b17a8fa4",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e02c702f094fa5afa44b17a8fa4",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d00004851c702f094fa5afa44b17a8fa4",
                width: 64,
              },
            ],
            name: "Unicorn",
            release_date: "2023-09-29",
            release_date_precision: "day",
            total_tracks: 14,
            type: "album",
            uri: "spotify:album:4uAOIj4Sxh4sL6G0v5kykv",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/3PALZKWkpwjRvBsRmhlVSS",
              },
              href: "https://api.spotify.com/v1/artists/3PALZKWkpwjRvBsRmhlVSS",
              id: "3PALZKWkpwjRvBsRmhlVSS",
              name: "Gunship",
              type: "artist",
              uri: "spotify:artist:3PALZKWkpwjRvBsRmhlVSS",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/3CGzpCMqpqHnafmn2PFQd9",
              },
              href: "https://api.spotify.com/v1/artists/3CGzpCMqpqHnafmn2PFQd9",
              id: "3CGzpCMqpqHnafmn2PFQd9",
              name: "Charlie Simpson",
              type: "artist",
              uri: "spotify:artist:3CGzpCMqpqHnafmn2PFQd9",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/0hxQtmgWiPtEsDPeIuKxXq",
              },
              href: "https://api.spotify.com/v1/artists/0hxQtmgWiPtEsDPeIuKxXq",
              id: "0hxQtmgWiPtEsDPeIuKxXq",
              name: "John Carpenter",
              type: "artist",
              uri: "spotify:artist:0hxQtmgWiPtEsDPeIuKxXq",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/0HyZTRkZ8UuhJKlIqBihqn",
              },
              href: "https://api.spotify.com/v1/artists/0HyZTRkZ8UuhJKlIqBihqn",
              id: "0HyZTRkZ8UuhJKlIqBihqn",
              name: "Tim Cappello",
              type: "artist",
              uri: "spotify:artist:0HyZTRkZ8UuhJKlIqBihqn",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 325573,
          explicit: false,
          external_ids: {
            isrc: "USA2P2251731",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/6sm8udxa0SvDUMJ2jchWe7",
          },
          href: "https://api.spotify.com/v1/tracks/6sm8udxa0SvDUMJ2jchWe7",
          id: "6sm8udxa0SvDUMJ2jchWe7",
          is_local: false,
          name: "Tech Noir 2 (feat. John Carpenter, Charlie Simpson)",
          popularity: 56,
          preview_url: "https://p.scdn.co/mp3-preview/d68f5907e48a76b6539cdbcfd4eb9b02e1f85f76?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 4,
          type: "track",
          uri: "spotify:track:6sm8udxa0SvDUMJ2jchWe7",
        },
        {
          album: {
            album_type: "ALBUM",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
                },
                href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
                id: "2gUtWSZwLhDitFVAezHsfv",
                name: "Best Frenz",
                type: "artist",
                uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
              },
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
                },
                href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
                id: "1UfzhwcOR4yfX7yHTPfC9m",
                name: "Joywave",
                type: "artist",
                uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/5h7BayM1hxhcxfDUC7d85U",
            },
            href: "https://api.spotify.com/v1/albums/5h7BayM1hxhcxfDUC7d85U",
            id: "5h7BayM1hxhcxfDUC7d85U",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b2731b13fb8312a0262bf9c018ec",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e021b13fb8312a0262bf9c018ec",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d000048511b13fb8312a0262bf9c018ec",
                width: 64,
              },
            ],
            name: "The Mall",
            release_date: "2023-08-11",
            release_date_precision: "day",
            total_tracks: 10,
            type: "album",
            uri: "spotify:album:5h7BayM1hxhcxfDUC7d85U",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
              },
              href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
              id: "2gUtWSZwLhDitFVAezHsfv",
              name: "Best Frenz",
              type: "artist",
              uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
              },
              href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
              id: "1UfzhwcOR4yfX7yHTPfC9m",
              name: "Joywave",
              type: "artist",
              uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 240186,
          explicit: false,
          external_ids: {
            isrc: "QZK6H2373645",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/2ReGrjKu7SfLVKtRm18GCs",
          },
          href: "https://api.spotify.com/v1/tracks/2ReGrjKu7SfLVKtRm18GCs",
          id: "2ReGrjKu7SfLVKtRm18GCs",
          is_local: false,
          name: "The Adirondacks",
          popularity: 33,
          preview_url: "https://p.scdn.co/mp3-preview/2d30c4e0f9e1769dd9c1f1724f50c94d93ac6f99?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 6,
          type: "track",
          uri: "spotify:track:2ReGrjKu7SfLVKtRm18GCs",
        },
        {
          album: {
            album_type: "ALBUM",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
                },
                href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
                id: "2gUtWSZwLhDitFVAezHsfv",
                name: "Best Frenz",
                type: "artist",
                uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
              },
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
                },
                href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
                id: "1UfzhwcOR4yfX7yHTPfC9m",
                name: "Joywave",
                type: "artist",
                uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/5h7BayM1hxhcxfDUC7d85U",
            },
            href: "https://api.spotify.com/v1/albums/5h7BayM1hxhcxfDUC7d85U",
            id: "5h7BayM1hxhcxfDUC7d85U",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b2731b13fb8312a0262bf9c018ec",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e021b13fb8312a0262bf9c018ec",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d000048511b13fb8312a0262bf9c018ec",
                width: 64,
              },
            ],
            name: "The Mall",
            release_date: "2023-08-11",
            release_date_precision: "day",
            total_tracks: 10,
            type: "album",
            uri: "spotify:album:5h7BayM1hxhcxfDUC7d85U",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
              },
              href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
              id: "2gUtWSZwLhDitFVAezHsfv",
              name: "Best Frenz",
              type: "artist",
              uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
              },
              href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
              id: "1UfzhwcOR4yfX7yHTPfC9m",
              name: "Joywave",
              type: "artist",
              uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 102840,
          explicit: false,
          external_ids: {
            isrc: "QZK6H2373642",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/1F9g5vvh5cchv6paEzzJmO",
          },
          href: "https://api.spotify.com/v1/tracks/1F9g5vvh5cchv6paEzzJmO",
          id: "1F9g5vvh5cchv6paEzzJmO",
          is_local: false,
          name: "Welcome To The Mall",
          popularity: 32,
          preview_url: "https://p.scdn.co/mp3-preview/34c8db05178d64498a8d36739c92d45e22e4d0ec?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 1,
          type: "track",
          uri: "spotify:track:1F9g5vvh5cchv6paEzzJmO",
        },
        {
          album: {
            album_type: "ALBUM",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/4MXUO7sVCaFgFjoTI5ox5c",
                },
                href: "https://api.spotify.com/v1/artists/4MXUO7sVCaFgFjoTI5ox5c",
                id: "4MXUO7sVCaFgFjoTI5ox5c",
                name: "Sufjan Stevens",
                type: "artist",
                uri: "spotify:artist:4MXUO7sVCaFgFjoTI5ox5c",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/2KqSL3vLfyVO7rrZJL9tUs",
            },
            href: "https://api.spotify.com/v1/albums/2KqSL3vLfyVO7rrZJL9tUs",
            id: "2KqSL3vLfyVO7rrZJL9tUs",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b27332a8498ea892ccd4fcf3f6cf",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e0232a8498ea892ccd4fcf3f6cf",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d0000485132a8498ea892ccd4fcf3f6cf",
                width: 64,
              },
            ],
            name: "Javelin",
            release_date: "2023-10-06",
            release_date_precision: "day",
            total_tracks: 10,
            type: "album",
            uri: "spotify:album:2KqSL3vLfyVO7rrZJL9tUs",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/4MXUO7sVCaFgFjoTI5ox5c",
              },
              href: "https://api.spotify.com/v1/artists/4MXUO7sVCaFgFjoTI5ox5c",
              id: "4MXUO7sVCaFgFjoTI5ox5c",
              name: "Sufjan Stevens",
              type: "artist",
              uri: "spotify:artist:4MXUO7sVCaFgFjoTI5ox5c",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 215424,
          explicit: false,
          external_ids: {
            isrc: "US64G2317101",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/2GEs9D56FRHqUAEwvlvBpw",
          },
          href: "https://api.spotify.com/v1/tracks/2GEs9D56FRHqUAEwvlvBpw",
          id: "2GEs9D56FRHqUAEwvlvBpw",
          is_local: false,
          name: "Goodbye Evergreen",
          popularity: 67,
          preview_url: "https://p.scdn.co/mp3-preview/ec5edbefc34a5971369f79ba2d2d31f1cf15fdd5?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 1,
          type: "track",
          uri: "spotify:track:2GEs9D56FRHqUAEwvlvBpw",
        },
        {
          album: {
            album_type: "ALBUM",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/3PALZKWkpwjRvBsRmhlVSS",
                },
                href: "https://api.spotify.com/v1/artists/3PALZKWkpwjRvBsRmhlVSS",
                id: "3PALZKWkpwjRvBsRmhlVSS",
                name: "Gunship",
                type: "artist",
                uri: "spotify:artist:3PALZKWkpwjRvBsRmhlVSS",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/4uAOIj4Sxh4sL6G0v5kykv",
            },
            href: "https://api.spotify.com/v1/albums/4uAOIj4Sxh4sL6G0v5kykv",
            id: "4uAOIj4Sxh4sL6G0v5kykv",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b273c702f094fa5afa44b17a8fa4",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e02c702f094fa5afa44b17a8fa4",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d00004851c702f094fa5afa44b17a8fa4",
                width: 64,
              },
            ],
            name: "Unicorn",
            release_date: "2023-09-29",
            release_date_precision: "day",
            total_tracks: 14,
            type: "album",
            uri: "spotify:album:4uAOIj4Sxh4sL6G0v5kykv",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/3PALZKWkpwjRvBsRmhlVSS",
              },
              href: "https://api.spotify.com/v1/artists/3PALZKWkpwjRvBsRmhlVSS",
              id: "3PALZKWkpwjRvBsRmhlVSS",
              name: "Gunship",
              type: "artist",
              uri: "spotify:artist:3PALZKWkpwjRvBsRmhlVSS",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/1OW9EXz8lzkvtX2tfnUI9r",
              },
              href: "https://api.spotify.com/v1/artists/1OW9EXz8lzkvtX2tfnUI9r",
              id: "1OW9EXz8lzkvtX2tfnUI9r",
              name: "Dave Lombardo",
              type: "artist",
              uri: "spotify:artist:1OW9EXz8lzkvtX2tfnUI9r",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/75fIuwXxhZ1atNzWLMrgF0",
              },
              href: "https://api.spotify.com/v1/artists/75fIuwXxhZ1atNzWLMrgF0",
              id: "75fIuwXxhZ1atNzWLMrgF0",
              name: "Tyler Bates",
              type: "artist",
              uri: "spotify:artist:75fIuwXxhZ1atNzWLMrgF0",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/6gKBfj9Z5WG7dSVUwAW4c4",
              },
              href: "https://api.spotify.com/v1/artists/6gKBfj9Z5WG7dSVUwAW4c4",
              id: "6gKBfj9Z5WG7dSVUwAW4c4",
              name: "Milkie Way",
              type: "artist",
              uri: "spotify:artist:6gKBfj9Z5WG7dSVUwAW4c4",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/0HyZTRkZ8UuhJKlIqBihqn",
              },
              href: "https://api.spotify.com/v1/artists/0HyZTRkZ8UuhJKlIqBihqn",
              id: "0HyZTRkZ8UuhJKlIqBihqn",
              name: "Tim Cappello",
              type: "artist",
              uri: "spotify:artist:0HyZTRkZ8UuhJKlIqBihqn",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 330493,
          explicit: false,
          external_ids: {
            isrc: "USA2P2251728",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/5WMn5op2E9aYUm5rk2ZNpY",
          },
          href: "https://api.spotify.com/v1/tracks/5WMn5op2E9aYUm5rk2ZNpY",
          id: "5WMn5op2E9aYUm5rk2ZNpY",
          is_local: false,
          name: "Monster in Paradise (feat. Milkie Way, Dave Lombardo, Tyler Bates)",
          popularity: 53,
          preview_url: "https://p.scdn.co/mp3-preview/0b021834cab23c3bdd5ea30a8f884388fc31eb53?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 1,
          type: "track",
          uri: "spotify:track:5WMn5op2E9aYUm5rk2ZNpY",
        },
      ],
    },
    medium_term: {
      items: [
        {
          album: {
            album_type: "SINGLE",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
                },
                href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
                id: "2gUtWSZwLhDitFVAezHsfv",
                name: "Best Frenz",
                type: "artist",
                uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
              },
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
                },
                href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
                id: "1UfzhwcOR4yfX7yHTPfC9m",
                name: "Joywave",
                type: "artist",
                uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/05Gtz8FQH99kwOJO1sUa1r",
            },
            href: "https://api.spotify.com/v1/albums/05Gtz8FQH99kwOJO1sUa1r",
            id: "05Gtz8FQH99kwOJO1sUa1r",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b273a3da2e3b54601d007defc4cd",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e02a3da2e3b54601d007defc4cd",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d00004851a3da2e3b54601d007defc4cd",
                width: 64,
              },
            ],
            name: "Throw Your Coins, Make A Wish",
            release_date: "2023-06-09",
            release_date_precision: "day",
            total_tracks: 2,
            type: "album",
            uri: "spotify:album:05Gtz8FQH99kwOJO1sUa1r",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
              },
              href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
              id: "2gUtWSZwLhDitFVAezHsfv",
              name: "Best Frenz",
              type: "artist",
              uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
              },
              href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
              id: "1UfzhwcOR4yfX7yHTPfC9m",
              name: "Joywave",
              type: "artist",
              uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 203106,
          explicit: false,
          external_ids: {
            isrc: "QZK6N2374764",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/76TXfqsg6GaJ8o179sRvvq",
          },
          href: "https://api.spotify.com/v1/tracks/76TXfqsg6GaJ8o179sRvvq",
          id: "76TXfqsg6GaJ8o179sRvvq",
          is_local: false,
          name: "Throw Your Coins, Make A Wish",
          popularity: 32,
          preview_url: "https://p.scdn.co/mp3-preview/fc5a9823136a70b2cd291dba0b2511599f32b520?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 1,
          type: "track",
          uri: "spotify:track:76TXfqsg6GaJ8o179sRvvq",
        },
        {
          album: {
            album_type: "ALBUM",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
                },
                href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
                id: "2gUtWSZwLhDitFVAezHsfv",
                name: "Best Frenz",
                type: "artist",
                uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
              },
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
                },
                href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
                id: "1UfzhwcOR4yfX7yHTPfC9m",
                name: "Joywave",
                type: "artist",
                uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/5h7BayM1hxhcxfDUC7d85U",
            },
            href: "https://api.spotify.com/v1/albums/5h7BayM1hxhcxfDUC7d85U",
            id: "5h7BayM1hxhcxfDUC7d85U",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b2731b13fb8312a0262bf9c018ec",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e021b13fb8312a0262bf9c018ec",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d000048511b13fb8312a0262bf9c018ec",
                width: 64,
              },
            ],
            name: "The Mall",
            release_date: "2023-08-11",
            release_date_precision: "day",
            total_tracks: 10,
            type: "album",
            uri: "spotify:album:5h7BayM1hxhcxfDUC7d85U",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
              },
              href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
              id: "2gUtWSZwLhDitFVAezHsfv",
              name: "Best Frenz",
              type: "artist",
              uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
              },
              href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
              id: "1UfzhwcOR4yfX7yHTPfC9m",
              name: "Joywave",
              type: "artist",
              uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 102840,
          explicit: false,
          external_ids: {
            isrc: "QZK6H2373642",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/1F9g5vvh5cchv6paEzzJmO",
          },
          href: "https://api.spotify.com/v1/tracks/1F9g5vvh5cchv6paEzzJmO",
          id: "1F9g5vvh5cchv6paEzzJmO",
          is_local: false,
          name: "Welcome To The Mall",
          popularity: 32,
          preview_url: "https://p.scdn.co/mp3-preview/34c8db05178d64498a8d36739c92d45e22e4d0ec?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 1,
          type: "track",
          uri: "spotify:track:1F9g5vvh5cchv6paEzzJmO",
        },
        {
          album: {
            album_type: "ALBUM",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/3PALZKWkpwjRvBsRmhlVSS",
                },
                href: "https://api.spotify.com/v1/artists/3PALZKWkpwjRvBsRmhlVSS",
                id: "3PALZKWkpwjRvBsRmhlVSS",
                name: "Gunship",
                type: "artist",
                uri: "spotify:artist:3PALZKWkpwjRvBsRmhlVSS",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/4uAOIj4Sxh4sL6G0v5kykv",
            },
            href: "https://api.spotify.com/v1/albums/4uAOIj4Sxh4sL6G0v5kykv",
            id: "4uAOIj4Sxh4sL6G0v5kykv",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b273c702f094fa5afa44b17a8fa4",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e02c702f094fa5afa44b17a8fa4",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d00004851c702f094fa5afa44b17a8fa4",
                width: 64,
              },
            ],
            name: "Unicorn",
            release_date: "2023-09-29",
            release_date_precision: "day",
            total_tracks: 14,
            type: "album",
            uri: "spotify:album:4uAOIj4Sxh4sL6G0v5kykv",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/3PALZKWkpwjRvBsRmhlVSS",
              },
              href: "https://api.spotify.com/v1/artists/3PALZKWkpwjRvBsRmhlVSS",
              id: "3PALZKWkpwjRvBsRmhlVSS",
              name: "Gunship",
              type: "artist",
              uri: "spotify:artist:3PALZKWkpwjRvBsRmhlVSS",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/3CGzpCMqpqHnafmn2PFQd9",
              },
              href: "https://api.spotify.com/v1/artists/3CGzpCMqpqHnafmn2PFQd9",
              id: "3CGzpCMqpqHnafmn2PFQd9",
              name: "Charlie Simpson",
              type: "artist",
              uri: "spotify:artist:3CGzpCMqpqHnafmn2PFQd9",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/0hxQtmgWiPtEsDPeIuKxXq",
              },
              href: "https://api.spotify.com/v1/artists/0hxQtmgWiPtEsDPeIuKxXq",
              id: "0hxQtmgWiPtEsDPeIuKxXq",
              name: "John Carpenter",
              type: "artist",
              uri: "spotify:artist:0hxQtmgWiPtEsDPeIuKxXq",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/0HyZTRkZ8UuhJKlIqBihqn",
              },
              href: "https://api.spotify.com/v1/artists/0HyZTRkZ8UuhJKlIqBihqn",
              id: "0HyZTRkZ8UuhJKlIqBihqn",
              name: "Tim Cappello",
              type: "artist",
              uri: "spotify:artist:0HyZTRkZ8UuhJKlIqBihqn",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 325573,
          explicit: false,
          external_ids: {
            isrc: "USA2P2251731",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/6sm8udxa0SvDUMJ2jchWe7",
          },
          href: "https://api.spotify.com/v1/tracks/6sm8udxa0SvDUMJ2jchWe7",
          id: "6sm8udxa0SvDUMJ2jchWe7",
          is_local: false,
          name: "Tech Noir 2 (feat. John Carpenter, Charlie Simpson)",
          popularity: 56,
          preview_url: "https://p.scdn.co/mp3-preview/d68f5907e48a76b6539cdbcfd4eb9b02e1f85f76?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 4,
          type: "track",
          uri: "spotify:track:6sm8udxa0SvDUMJ2jchWe7",
        },
        {
          album: {
            album_type: "COMPILATION",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/0LyfQWJT6nXafLPZqxe9Of",
                },
                href: "https://api.spotify.com/v1/artists/0LyfQWJT6nXafLPZqxe9Of",
                id: "0LyfQWJT6nXafLPZqxe9Of",
                name: "Various Artists",
                type: "artist",
                uri: "spotify:artist:0LyfQWJT6nXafLPZqxe9Of",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/0ZUjcQZgz7tZ0KKhQgS3WB",
            },
            href: "https://api.spotify.com/v1/albums/0ZUjcQZgz7tZ0KKhQgS3WB",
            id: "0ZUjcQZgz7tZ0KKhQgS3WB",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b273f260e780e1dfbf5187f5c640",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e02f260e780e1dfbf5187f5c640",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d00004851f260e780e1dfbf5187f5c640",
                width: 64,
              },
            ],
            name: "Civilization VI: New Frontier Pass, Vol. 1 (Original Game Soundtrack)",
            release_date: "2020-11-03",
            release_date_precision: "day",
            total_tracks: 20,
            type: "album",
            uri: "spotify:album:0ZUjcQZgz7tZ0KKhQgS3WB",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/0VTum9bqgFqbvCA93F3Po6",
              },
              href: "https://api.spotify.com/v1/artists/0VTum9bqgFqbvCA93F3Po6",
              id: "0VTum9bqgFqbvCA93F3Po6",
              name: "Martinů Voices",
              type: "artist",
              uri: "spotify:artist:0VTum9bqgFqbvCA93F3Po6",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/0DGndOUIKK0LFt0mOKYT1h",
              },
              href: "https://api.spotify.com/v1/artists/0DGndOUIKK0LFt0mOKYT1h",
              id: "0DGndOUIKK0LFt0mOKYT1h",
              name: "Niccolo Seligmann",
              type: "artist",
              uri: "spotify:artist:0DGndOUIKK0LFt0mOKYT1h",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 427429,
          explicit: false,
          external_ids: {
            isrc: "TCAFF2004607",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/0gSy84Fl22VMIXCQ54GSYv",
          },
          href: "https://api.spotify.com/v1/tracks/0gSy84Fl22VMIXCQ54GSYv",
          id: "0gSy84Fl22VMIXCQ54GSYv",
          is_local: false,
          name: "Byzantium (The Ancient Era)",
          popularity: 20,
          preview_url: "https://p.scdn.co/mp3-preview/510de4793e944c5d138213283f5d2012240c2f27?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 13,
          type: "track",
          uri: "spotify:track:0gSy84Fl22VMIXCQ54GSYv",
        },
        {
          album: {
            album_type: "ALBUM",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
                },
                href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
                id: "2gUtWSZwLhDitFVAezHsfv",
                name: "Best Frenz",
                type: "artist",
                uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
              },
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
                },
                href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
                id: "1UfzhwcOR4yfX7yHTPfC9m",
                name: "Joywave",
                type: "artist",
                uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/5h7BayM1hxhcxfDUC7d85U",
            },
            href: "https://api.spotify.com/v1/albums/5h7BayM1hxhcxfDUC7d85U",
            id: "5h7BayM1hxhcxfDUC7d85U",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b2731b13fb8312a0262bf9c018ec",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e021b13fb8312a0262bf9c018ec",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d000048511b13fb8312a0262bf9c018ec",
                width: 64,
              },
            ],
            name: "The Mall",
            release_date: "2023-08-11",
            release_date_precision: "day",
            total_tracks: 10,
            type: "album",
            uri: "spotify:album:5h7BayM1hxhcxfDUC7d85U",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
              },
              href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
              id: "2gUtWSZwLhDitFVAezHsfv",
              name: "Best Frenz",
              type: "artist",
              uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
              },
              href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
              id: "1UfzhwcOR4yfX7yHTPfC9m",
              name: "Joywave",
              type: "artist",
              uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 240186,
          explicit: false,
          external_ids: {
            isrc: "QZK6H2373645",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/2ReGrjKu7SfLVKtRm18GCs",
          },
          href: "https://api.spotify.com/v1/tracks/2ReGrjKu7SfLVKtRm18GCs",
          id: "2ReGrjKu7SfLVKtRm18GCs",
          is_local: false,
          name: "The Adirondacks",
          popularity: 33,
          preview_url: "https://p.scdn.co/mp3-preview/2d30c4e0f9e1769dd9c1f1724f50c94d93ac6f99?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 6,
          type: "track",
          uri: "spotify:track:2ReGrjKu7SfLVKtRm18GCs",
        },
      ],
    },
    long_term: {
      items: [
        {
          album: {
            album_type: "SINGLE",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
                },
                href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
                id: "2gUtWSZwLhDitFVAezHsfv",
                name: "Best Frenz",
                type: "artist",
                uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
              },
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
                },
                href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
                id: "1UfzhwcOR4yfX7yHTPfC9m",
                name: "Joywave",
                type: "artist",
                uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
              },
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/74QeguyCOML1X9s4dXU2Dz",
                },
                href: "https://api.spotify.com/v1/artists/74QeguyCOML1X9s4dXU2Dz",
                id: "74QeguyCOML1X9s4dXU2Dz",
                name: "Jason Suwito",
                type: "artist",
                uri: "spotify:artist:74QeguyCOML1X9s4dXU2Dz",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/173gdkW3M43QxhzCHaTEwX",
            },
            href: "https://api.spotify.com/v1/albums/173gdkW3M43QxhzCHaTEwX",
            id: "173gdkW3M43QxhzCHaTEwX",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b27368d2fd4acf0b6d1808b0ea03",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e0268d2fd4acf0b6d1808b0ea03",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d0000485168d2fd4acf0b6d1808b0ea03",
                width: 64,
              },
            ],
            name: "30% Off! (Now With 25% More!)",
            release_date: "2021-02-19",
            release_date_precision: "day",
            total_tracks: 5,
            type: "album",
            uri: "spotify:album:173gdkW3M43QxhzCHaTEwX",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
              },
              href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
              id: "2gUtWSZwLhDitFVAezHsfv",
              name: "Best Frenz",
              type: "artist",
              uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
              },
              href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
              id: "1UfzhwcOR4yfX7yHTPfC9m",
              name: "Joywave",
              type: "artist",
              uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/74QeguyCOML1X9s4dXU2Dz",
              },
              href: "https://api.spotify.com/v1/artists/74QeguyCOML1X9s4dXU2Dz",
              id: "74QeguyCOML1X9s4dXU2Dz",
              name: "Jason Suwito",
              type: "artist",
              uri: "spotify:artist:74QeguyCOML1X9s4dXU2Dz",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 238546,
          explicit: false,
          external_ids: {
            isrc: "QZHN62074384",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/5nd388N2RM419Jq23GRACT",
          },
          href: "https://api.spotify.com/v1/tracks/5nd388N2RM419Jq23GRACT",
          id: "5nd388N2RM419Jq23GRACT",
          is_local: false,
          name: "Ugly Ending",
          popularity: 45,
          preview_url: "https://p.scdn.co/mp3-preview/3645106ecc63a4748f92fcdc7be625a845e66df5?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 1,
          type: "track",
          uri: "spotify:track:5nd388N2RM419Jq23GRACT",
        },
        {
          album: {
            album_type: "ALBUM",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/1HOeqtP7tHkKNJNLzQ2tnr",
                },
                href: "https://api.spotify.com/v1/artists/1HOeqtP7tHkKNJNLzQ2tnr",
                id: "1HOeqtP7tHkKNJNLzQ2tnr",
                name: "Everything Everything",
                type: "artist",
                uri: "spotify:artist:1HOeqtP7tHkKNJNLzQ2tnr",
              },
            ],
            available_markets: [],
            external_urls: {
              spotify: "https://open.spotify.com/album/6AvpMWv1ZF48aSS3FDKast",
            },
            href: "https://api.spotify.com/v1/albums/6AvpMWv1ZF48aSS3FDKast",
            id: "6AvpMWv1ZF48aSS3FDKast",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b27354ae10c175eaf83b6596bf5c",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e0254ae10c175eaf83b6596bf5c",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d0000485154ae10c175eaf83b6596bf5c",
                width: 64,
              },
            ],
            name: "RE-ANIMATOR",
            release_date: "2020-09-11",
            release_date_precision: "day",
            total_tracks: 11,
            type: "album",
            uri: "spotify:album:6AvpMWv1ZF48aSS3FDKast",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/1HOeqtP7tHkKNJNLzQ2tnr",
              },
              href: "https://api.spotify.com/v1/artists/1HOeqtP7tHkKNJNLzQ2tnr",
              id: "1HOeqtP7tHkKNJNLzQ2tnr",
              name: "Everything Everything",
              type: "artist",
              uri: "spotify:artist:1HOeqtP7tHkKNJNLzQ2tnr",
            },
          ],
          available_markets: [],
          disc_number: 1,
          duration_ms: 244480,
          explicit: false,
          external_ids: {
            isrc: "GBWWP2005911",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/1KUXZIqGRp14Py0WEso28g",
          },
          href: "https://api.spotify.com/v1/tracks/1KUXZIqGRp14Py0WEso28g",
          id: "1KUXZIqGRp14Py0WEso28g",
          is_local: false,
          name: "The Actor",
          popularity: 0,
          preview_url: null,
          track_number: 10,
          type: "track",
          uri: "spotify:track:1KUXZIqGRp14Py0WEso28g",
        },
        {
          album: {
            album_type: "ALBUM",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/5xeBMeW0YzWIXSVzAxhM8O",
                },
                href: "https://api.spotify.com/v1/artists/5xeBMeW0YzWIXSVzAxhM8O",
                id: "5xeBMeW0YzWIXSVzAxhM8O",
                name: "of Montreal",
                type: "artist",
                uri: "spotify:artist:5xeBMeW0YzWIXSVzAxhM8O",
              },
            ],
            available_markets: [],
            external_urls: {
              spotify: "https://open.spotify.com/album/1GEvc3u1Fu0CoKPesy1gDJ",
            },
            href: "https://api.spotify.com/v1/albums/1GEvc3u1Fu0CoKPesy1gDJ",
            id: "1GEvc3u1Fu0CoKPesy1gDJ",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b273a5b21e864087c7ab7f448f91",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e02a5b21e864087c7ab7f448f91",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d00004851a5b21e864087c7ab7f448f91",
                width: 64,
              },
            ],
            name: "Paralytic Stalks",
            release_date: "2012-02-07",
            release_date_precision: "day",
            total_tracks: 9,
            type: "album",
            uri: "spotify:album:1GEvc3u1Fu0CoKPesy1gDJ",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/5xeBMeW0YzWIXSVzAxhM8O",
              },
              href: "https://api.spotify.com/v1/artists/5xeBMeW0YzWIXSVzAxhM8O",
              id: "5xeBMeW0YzWIXSVzAxhM8O",
              name: "of Montreal",
              type: "artist",
              uri: "spotify:artist:5xeBMeW0YzWIXSVzAxhM8O",
            },
          ],
          available_markets: [],
          disc_number: 1,
          duration_ms: 329733,
          explicit: false,
          external_ids: {
            isrc: "US3R41223304",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/1t5eRyRqqt6F8P5xeZcS2z",
          },
          href: "https://api.spotify.com/v1/tracks/1t5eRyRqqt6F8P5xeZcS2z",
          id: "1t5eRyRqqt6F8P5xeZcS2z",
          is_local: false,
          name: "We Will Commit Wolf Murder",
          popularity: 0,
          preview_url: null,
          track_number: 4,
          type: "track",
          uri: "spotify:track:1t5eRyRqqt6F8P5xeZcS2z",
        },
        {
          album: {
            album_type: "SINGLE",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
                },
                href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
                id: "2gUtWSZwLhDitFVAezHsfv",
                name: "Best Frenz",
                type: "artist",
                uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
              },
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
                },
                href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
                id: "1UfzhwcOR4yfX7yHTPfC9m",
                name: "Joywave",
                type: "artist",
                uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
              },
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/74QeguyCOML1X9s4dXU2Dz",
                },
                href: "https://api.spotify.com/v1/artists/74QeguyCOML1X9s4dXU2Dz",
                id: "74QeguyCOML1X9s4dXU2Dz",
                name: "Jason Suwito",
                type: "artist",
                uri: "spotify:artist:74QeguyCOML1X9s4dXU2Dz",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/173gdkW3M43QxhzCHaTEwX",
            },
            href: "https://api.spotify.com/v1/albums/173gdkW3M43QxhzCHaTEwX",
            id: "173gdkW3M43QxhzCHaTEwX",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b27368d2fd4acf0b6d1808b0ea03",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e0268d2fd4acf0b6d1808b0ea03",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d0000485168d2fd4acf0b6d1808b0ea03",
                width: 64,
              },
            ],
            name: "30% Off! (Now With 25% More!)",
            release_date: "2021-02-19",
            release_date_precision: "day",
            total_tracks: 5,
            type: "album",
            uri: "spotify:album:173gdkW3M43QxhzCHaTEwX",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
              },
              href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
              id: "2gUtWSZwLhDitFVAezHsfv",
              name: "Best Frenz",
              type: "artist",
              uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/1UfzhwcOR4yfX7yHTPfC9m",
              },
              href: "https://api.spotify.com/v1/artists/1UfzhwcOR4yfX7yHTPfC9m",
              id: "1UfzhwcOR4yfX7yHTPfC9m",
              name: "Joywave",
              type: "artist",
              uri: "spotify:artist:1UfzhwcOR4yfX7yHTPfC9m",
            },
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/74QeguyCOML1X9s4dXU2Dz",
              },
              href: "https://api.spotify.com/v1/artists/74QeguyCOML1X9s4dXU2Dz",
              id: "74QeguyCOML1X9s4dXU2Dz",
              name: "Jason Suwito",
              type: "artist",
              uri: "spotify:artist:74QeguyCOML1X9s4dXU2Dz",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 207373,
          explicit: false,
          external_ids: {
            isrc: "QZHN92016026",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/7mRbbFn1Ip7hp1NoJQjOtB",
          },
          href: "https://api.spotify.com/v1/tracks/7mRbbFn1Ip7hp1NoJQjOtB",
          id: "7mRbbFn1Ip7hp1NoJQjOtB",
          is_local: false,
          name: "30% Off!",
          popularity: 43,
          preview_url: "https://p.scdn.co/mp3-preview/daf327f046e0b81b1c353ee7d2a2dcb495e70278?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 4,
          type: "track",
          uri: "spotify:track:7mRbbFn1Ip7hp1NoJQjOtB",
        },
        {
          album: {
            album_type: "ALBUM",
            artists: [
              {
                external_urls: {
                  spotify: "https://open.spotify.com/artist/7MoIc5s9KXolCBH1fy9kkw",
                },
                href: "https://api.spotify.com/v1/artists/7MoIc5s9KXolCBH1fy9kkw",
                id: "7MoIc5s9KXolCBH1fy9kkw",
                name: "Japanese Breakfast",
                type: "artist",
                uri: "spotify:artist:7MoIc5s9KXolCBH1fy9kkw",
              },
            ],
            available_markets: [
              "AD",
              "AE",
              "AR",
              "AT",
              "AU",
              "BE",
              "BG",
              "BH",
              "BO",
              "BR",
              "CA",
              "CH",
              "CL",
              "CO",
              "CR",
              "CY",
              "CZ",
              "DE",
              "DK",
              "DO",
              "DZ",
              "EC",
              "EE",
              "EG",
              "ES",
              "FI",
              "FR",
              "GB",
              "GR",
              "GT",
              "HK",
              "HN",
              "HU",
              "ID",
              "IE",
              "IL",
              "IN",
              "IS",
              "IT",
              "JO",
              "JP",
              "KW",
              "LB",
              "LI",
              "LT",
              "LU",
              "LV",
              "MA",
              "MC",
              "MT",
              "MX",
              "MY",
              "NI",
              "NL",
              "NO",
              "NZ",
              "OM",
              "PA",
              "PE",
              "PH",
              "PL",
              "PS",
              "PT",
              "PY",
              "QA",
              "RO",
              "SA",
              "SE",
              "SG",
              "SK",
              "SV",
              "TH",
              "TN",
              "TR",
              "TW",
              "US",
              "UY",
              "VN",
              "ZA",
            ],
            external_urls: {
              spotify: "https://open.spotify.com/album/1uD1kdwTWH1DZQZqGKz6rY",
            },
            href: "https://api.spotify.com/v1/albums/1uD1kdwTWH1DZQZqGKz6rY",
            id: "1uD1kdwTWH1DZQZqGKz6rY",
            images: [
              {
                height: 640,
                url: "https://i.scdn.co/image/ab67616d0000b273b103a69ed742a7735a2ba7e8",
                width: 640,
              },
              {
                height: 300,
                url: "https://i.scdn.co/image/ab67616d00001e02b103a69ed742a7735a2ba7e8",
                width: 300,
              },
              {
                height: 64,
                url: "https://i.scdn.co/image/ab67616d00004851b103a69ed742a7735a2ba7e8",
                width: 64,
              },
            ],
            name: "Jubilee",
            release_date: "2021-06-04",
            release_date_precision: "day",
            total_tracks: 10,
            type: "album",
            uri: "spotify:album:1uD1kdwTWH1DZQZqGKz6rY",
          },
          artists: [
            {
              external_urls: {
                spotify: "https://open.spotify.com/artist/7MoIc5s9KXolCBH1fy9kkw",
              },
              href: "https://api.spotify.com/v1/artists/7MoIc5s9KXolCBH1fy9kkw",
              id: "7MoIc5s9KXolCBH1fy9kkw",
              name: "Japanese Breakfast",
              type: "artist",
              uri: "spotify:artist:7MoIc5s9KXolCBH1fy9kkw",
            },
          ],
          available_markets: [
            "AD",
            "AE",
            "AR",
            "AT",
            "AU",
            "BE",
            "BG",
            "BH",
            "BO",
            "BR",
            "CA",
            "CH",
            "CL",
            "CO",
            "CR",
            "CY",
            "CZ",
            "DE",
            "DK",
            "DO",
            "DZ",
            "EC",
            "EE",
            "EG",
            "ES",
            "FI",
            "FR",
            "GB",
            "GR",
            "GT",
            "HK",
            "HN",
            "HU",
            "ID",
            "IE",
            "IL",
            "IN",
            "IS",
            "IT",
            "JO",
            "JP",
            "KW",
            "LB",
            "LI",
            "LT",
            "LU",
            "LV",
            "MA",
            "MC",
            "MT",
            "MX",
            "MY",
            "NI",
            "NL",
            "NO",
            "NZ",
            "OM",
            "PA",
            "PE",
            "PH",
            "PL",
            "PS",
            "PT",
            "PY",
            "QA",
            "RO",
            "SA",
            "SE",
            "SG",
            "SK",
            "SV",
            "TH",
            "TN",
            "TR",
            "TW",
            "US",
            "UY",
            "VN",
            "ZA",
          ],
          disc_number: 1,
          duration_ms: 220316,
          explicit: false,
          external_ids: {
            isrc: "USJ5G2022501",
          },
          external_urls: {
            spotify: "https://open.spotify.com/track/3zyqphgXvgHe436IMKeey3",
          },
          href: "https://api.spotify.com/v1/tracks/3zyqphgXvgHe436IMKeey3",
          id: "3zyqphgXvgHe436IMKeey3",
          is_local: false,
          name: "Paprika",
          popularity: 56,
          preview_url: "https://p.scdn.co/mp3-preview/140ccb94e9ddeb8aed31b6da1233e57666e948e4?cid=f655ecf166914d6b9ecf6d7abcc91c52",
          track_number: 1,
          type: "track",
          uri: "spotify:track:3zyqphgXvgHe436IMKeey3",
        },
      ],
    },
  },
  artistData: {
    short_term: {
      items: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/3PALZKWkpwjRvBsRmhlVSS",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["popwave"],
          href: "https://api.spotify.com/v1/artists/3PALZKWkpwjRvBsRmhlVSS",
          id: "3PALZKWkpwjRvBsRmhlVSS",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eb96a96f31fdff768dd1245905",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab6761610000517496a96f31fdff768dd1245905",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f17896a96f31fdff768dd1245905",
              width: 160,
            },
          ],
          name: "Gunship",
          popularity: 55,
          type: "artist",
          uri: "spotify:artist:3PALZKWkpwjRvBsRmhlVSS",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: [],
          href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
          id: "2gUtWSZwLhDitFVAezHsfv",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eb8d2104d4cb504d383b9e9ce6",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab676161000051748d2104d4cb504d383b9e9ce6",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f1788d2104d4cb504d383b9e9ce6",
              width: 160,
            },
          ],
          name: "Best Frenz",
          popularity: 36,
          type: "artist",
          uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/4MXUO7sVCaFgFjoTI5ox5c",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["art pop", "baroque pop", "chamber pop", "indie pop", "indie rock", "singer-songwriter"],
          href: "https://api.spotify.com/v1/artists/4MXUO7sVCaFgFjoTI5ox5c",
          id: "4MXUO7sVCaFgFjoTI5ox5c",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5ebb80dd6b23c5c04d62d9aa0c6",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab67616100005174b80dd6b23c5c04d62d9aa0c6",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f178b80dd6b23c5c04d62d9aa0c6",
              width: 160,
            },
          ],
          name: "Sufjan Stevens",
          popularity: 72,
          type: "artist",
          uri: "spotify:artist:4MXUO7sVCaFgFjoTI5ox5c",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/1btWGBz4Uu1HozTwb2Lm8A",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["minneapolis indie", "modern rock", "pov: indie"],
          href: "https://api.spotify.com/v1/artists/1btWGBz4Uu1HozTwb2Lm8A",
          id: "1btWGBz4Uu1HozTwb2Lm8A",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eba540d61efaef0e682025c509",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab67616100005174a540d61efaef0e682025c509",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f178a540d61efaef0e682025c509",
              width: 160,
            },
          ],
          name: "Hippo Campus",
          popularity: 59,
          type: "artist",
          uri: "spotify:artist:1btWGBz4Uu1HozTwb2Lm8A",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/7xWU2A2lw1xf4zTjKhkrGK",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["stomp pop"],
          href: "https://api.spotify.com/v1/artists/7xWU2A2lw1xf4zTjKhkrGK",
          id: "7xWU2A2lw1xf4zTjKhkrGK",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eb55229ad1e9f45be6e601ef1c",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab6761610000517455229ad1e9f45be6e601ef1c",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f17855229ad1e9f45be6e601ef1c",
              width: 160,
            },
          ],
          name: "Miniature Tigers",
          popularity: 49,
          type: "artist",
          uri: "spotify:artist:7xWU2A2lw1xf4zTjKhkrGK",
        },
      ],
    },
    medium_term: {
      items: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/1OFCjpSAsyIOWWzIAYb3OI",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["video game music"],
          href: "https://api.spotify.com/v1/artists/1OFCjpSAsyIOWWzIAYb3OI",
          id: "1OFCjpSAsyIOWWzIAYb3OI",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eb81dfa97becd8f3c8e95a5e5d",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab6761610000517481dfa97becd8f3c8e95a5e5d",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f17881dfa97becd8f3c8e95a5e5d",
              width: 160,
            },
          ],
          name: "Geoff Knorr",
          popularity: 38,
          type: "artist",
          uri: "spotify:artist:1OFCjpSAsyIOWWzIAYb3OI",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/2gUtWSZwLhDitFVAezHsfv",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: [],
          href: "https://api.spotify.com/v1/artists/2gUtWSZwLhDitFVAezHsfv",
          id: "2gUtWSZwLhDitFVAezHsfv",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eb8d2104d4cb504d383b9e9ce6",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab676161000051748d2104d4cb504d383b9e9ce6",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f1788d2104d4cb504d383b9e9ce6",
              width: 160,
            },
          ],
          name: "Best Frenz",
          popularity: 36,
          type: "artist",
          uri: "spotify:artist:2gUtWSZwLhDitFVAezHsfv",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/3PALZKWkpwjRvBsRmhlVSS",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["popwave"],
          href: "https://api.spotify.com/v1/artists/3PALZKWkpwjRvBsRmhlVSS",
          id: "3PALZKWkpwjRvBsRmhlVSS",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eb96a96f31fdff768dd1245905",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab6761610000517496a96f31fdff768dd1245905",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f17896a96f31fdff768dd1245905",
              width: 160,
            },
          ],
          name: "Gunship",
          popularity: 55,
          type: "artist",
          uri: "spotify:artist:3PALZKWkpwjRvBsRmhlVSS",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/16lGevDffmuRakKVvdJ983",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["dark cabaret", "louisville indie", "steampunk"],
          href: "https://api.spotify.com/v1/artists/16lGevDffmuRakKVvdJ983",
          id: "16lGevDffmuRakKVvdJ983",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eba99e6039483b51e0d719e2fc",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab67616100005174a99e6039483b51e0d719e2fc",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f178a99e6039483b51e0d719e2fc",
              width: 160,
            },
          ],
          name: "Dirt Poor Robins",
          popularity: 42,
          type: "artist",
          uri: "spotify:artist:16lGevDffmuRakKVvdJ983",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/1btWGBz4Uu1HozTwb2Lm8A",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["minneapolis indie", "modern rock", "pov: indie"],
          href: "https://api.spotify.com/v1/artists/1btWGBz4Uu1HozTwb2Lm8A",
          id: "1btWGBz4Uu1HozTwb2Lm8A",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eba540d61efaef0e682025c509",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab67616100005174a540d61efaef0e682025c509",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f178a540d61efaef0e682025c509",
              width: 160,
            },
          ],
          name: "Hippo Campus",
          popularity: 59,
          type: "artist",
          uri: "spotify:artist:1btWGBz4Uu1HozTwb2Lm8A",
        },
      ],
    },
    long_term: {
      items: [
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/1HOeqtP7tHkKNJNLzQ2tnr",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["british indie rock", "experimental pop", "modern rock", "new rave", "northern irish indie", "shimmer pop"],
          href: "https://api.spotify.com/v1/artists/1HOeqtP7tHkKNJNLzQ2tnr",
          id: "1HOeqtP7tHkKNJNLzQ2tnr",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eb4ae4999521cdc4a1f35c437e",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab676161000051744ae4999521cdc4a1f35c437e",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f1784ae4999521cdc4a1f35c437e",
              width: 160,
            },
          ],
          name: "Everything Everything",
          popularity: 48,
          type: "artist",
          uri: "spotify:artist:1HOeqtP7tHkKNJNLzQ2tnr",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/5xeBMeW0YzWIXSVzAxhM8O",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["art pop", "baroque pop", "dance-punk", "elephant 6", "indie rock", "lo-fi", "noise pop"],
          href: "https://api.spotify.com/v1/artists/5xeBMeW0YzWIXSVzAxhM8O",
          id: "5xeBMeW0YzWIXSVzAxhM8O",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5ebf3e93c0583fdb0c1b083ccf8",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab67616100005174f3e93c0583fdb0c1b083ccf8",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f178f3e93c0583fdb0c1b083ccf8",
              width: 160,
            },
          ],
          name: "of Montreal",
          popularity: 49,
          type: "artist",
          uri: "spotify:artist:5xeBMeW0YzWIXSVzAxhM8O",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/16lGevDffmuRakKVvdJ983",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["dark cabaret", "louisville indie", "steampunk"],
          href: "https://api.spotify.com/v1/artists/16lGevDffmuRakKVvdJ983",
          id: "16lGevDffmuRakKVvdJ983",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eba99e6039483b51e0d719e2fc",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab67616100005174a99e6039483b51e0d719e2fc",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f178a99e6039483b51e0d719e2fc",
              width: 160,
            },
          ],
          name: "Dirt Poor Robins",
          popularity: 42,
          type: "artist",
          uri: "spotify:artist:16lGevDffmuRakKVvdJ983",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/1OFCjpSAsyIOWWzIAYb3OI",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["video game music"],
          href: "https://api.spotify.com/v1/artists/1OFCjpSAsyIOWWzIAYb3OI",
          id: "1OFCjpSAsyIOWWzIAYb3OI",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eb81dfa97becd8f3c8e95a5e5d",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab6761610000517481dfa97becd8f3c8e95a5e5d",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f17881dfa97becd8f3c8e95a5e5d",
              width: 160,
            },
          ],
          name: "Geoff Knorr",
          popularity: 38,
          type: "artist",
          uri: "spotify:artist:1OFCjpSAsyIOWWzIAYb3OI",
        },
        {
          external_urls: {
            spotify: "https://open.spotify.com/artist/4S33gEeiCjwfD1FJKfMT3Y",
          },
          followers: {
            href: null,
            total: 0,
          },
          genres: ["anime score"],
          href: "https://api.spotify.com/v1/artists/4S33gEeiCjwfD1FJKfMT3Y",
          id: "4S33gEeiCjwfD1FJKfMT3Y",
          images: [
            {
              height: 640,
              url: "https://i.scdn.co/image/ab6761610000e5eb4d7889d9cf5a026c646cb200",
              width: 640,
            },
            {
              height: 320,
              url: "https://i.scdn.co/image/ab676161000051744d7889d9cf5a026c646cb200",
              width: 320,
            },
            {
              height: 160,
              url: "https://i.scdn.co/image/ab6761610000f1784d7889d9cf5a026c646cb200",
              width: 160,
            },
          ],
          name: "Kevin Penkin",
          popularity: 51,
          type: "artist",
          uri: "spotify:artist:4S33gEeiCjwfD1FJKfMT3Y",
        },
      ],
    },
  },
};

export { info1, info2, demoButton, mockedData };
